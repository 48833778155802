import React, { useState } from 'react'


export default function TextForm(props) {
    const handleUpclick = ()=>{
      console.log("Uppercase was clicked" + text);
      let newText = text.toUpperCase();
      setText(newText);
      props.showAlert("converted to Uppercase", "success")
      //setText("you have clicked on handleUpclick")
    }

    const handleUpclick2 = ()=>{
      //console.log("Lowercase was clicked" + text);
      let newText = text.toLowerCase();
      setText(newText);
      //setText("you have clicked on handleUpclick")
    }

    const handleOnSpeak = ()=>{
      let msg = new SpeechSynthesisUtterance();
      msg.text = text;
      window.speechSynthesis.speak(msg);
     
    }

    const handleOnChange = (event)=>{
      //console.log("On Change");
      setText(event.target.value);
    }
    
    const handleClearText = ()=>{
      let newText = "";
      setText(newText);
    }

    const handleExtraSpaces = ()=> {
      let spaceText = text.split(/[ ]+/);
      setText(spaceText.join(" "))
    }
    //event to add more content to the existing value in textarea content setText(event.target.value);

    //we used useState Hook, help in making a state variable
    const [text, setText] = useState('Enter text here....');
    //we initialize both text and setText using useState hook
    //text is the value 'Enter text here' that we have iniatiased using useState.
    //setText is the function use to change the text state.
    // text = "new text"; wrong way to change the state
    // setText = "new test"; right way to change the state
    //console.log(useState('Enter text here uodated through '))

  return (
    <>
    <div className="container mt-6" style={{color: props.mode==='light'? '#343b42':'white'}}>
        <h1>{props.heading} </h1>
        <div className="mb-3">
        <textarea style={{backgroundColor: props.mode==='light'? 'white':'#343b42', color: props.mode==='light'? '#343b42':'white'}} className="form-control" value={text} id="myBox" onChange={handleOnChange} rows="8"></textarea>
        </div>
        <button className="btn btn primary mx-2" onClick={handleUpclick}>Convert to Uppercase</button>
       
        <button className="btn btn primary mx-2" onClick={handleUpclick2}>Convert to Lowercase</button>

        <button onClick={handleOnSpeak} className="btn btn-warning mx-2 my-2">Speak</button>

        <button className="btn btn primary mx-2" onClick={handleClearText}>Clear Text</button>

        <button className="btn btn primary mx-2" onClick={handleExtraSpaces}>Remove Extra Spaces</button>

        

    </div>
    <div className="container my-4">
      <h1>Your Text Summary </h1>
      <p>{text.split(" ").length} words and {text.length} characters</p>
      <p>{0.008 * text.split(" ").length} Minutes to read</p>
      <h3>Preview</h3>
      <p>{text.length>0?text:"Enter something in the textbox above to preview it."}</p>
    </div>
    </>
  )
}

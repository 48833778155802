import React from 'react'

function Alert(props) {

  // const capitalize = (word)=>{
  //    const lower = word.toLowerCase();
  //    return lower.charAt(0).toUpperCase() + lower.slice(1);
  // }

  return (
    //props.alert && it is false don't show 
    props.alert && <div className={`alert alert-${props.alert.type} alert-dismissible fade show`} role="alert">
        {/* I want to exchange value or pass value thats why using prop*/}
        {/* <strong>Holy guacamole!</strong> You should check in on some of those fields below. */}
        <strong>{props.alert.type} : </strong>{props.alert.msg}
        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
  )
}

export default Alert;


import { useState } from 'react';

import About from './components/About.js';
import Navbar from './components/Navbar.js';
import TextForm from './components/TextForm.js';
import Alert from './components/Alert.js';
import React from "react";
import {Routes, Route } from 'react-router-dom';


function App() {
  // alert is the object, setAlert is the function to change value, null is the default value.
  const [alert, setAlert] = useState('null');
  

  //type - There are 4 type of alert -alert, success, warning, danger
  const showAlert = (message, type)=>{
       setAlert({
        msg: message,
        type: type
       })
  }
  const [mode, setMode]  = useState('light'); // whether dark mode is enabled or not.
  //we cannot use = sign in state mode.
  const toggleMode = ()=>{
    if(mode === 'light'){
      setMode('dark')
      // document.body.style.backgroundColor="#212529";
      document.body.style.backgroundColor="#343a40";
      document.body.style.color="white";
    showAlert("Dark Mode has been enabled","success");
    document.title ='TextEditor - Dark Mode';
    setInterval(() => {
      document.title ='TextEditor is amazing editor';
    }, 2000);
    setInterval(() => {
      document.title ='Install TextEditor now';
    }, 1500);


    }else{
      setMode('light')
      document.body.style.backgroundColor="white";
      document.body.style.color="black";
      showAlert("Light Mode has been enabled","success");
      document.title = 'TextEditor - Light Mode';

    }
  }
  return (
  <>
    {/* <Navbar  title="Course-1" aboutText="About us" /> */}
    
    <Navbar aboutText="About us"  mode={mode} toggleMode={toggleMode} />
    <Alert alert={alert} />
    <div className="container my-3"> 
    <Routes>
         {/* <About /> */}
         <Route exact path='/' element={<TextForm showAlert={showAlert} mode={mode}  heading="Enter The Text To Analyse below" />
} />     
         <Route  exact path='/About' element={<About />}/>
    </Routes>     
    </div>
    
  </>
  );
}

export default App;
